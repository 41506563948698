import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
import '../../assets/scripts/jquery.nicescroll.min'

if (window.devicePixelRatio < 2) {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        $('body').niceScroll({
            mousescrollstep: 40,
            scrollspeed: 80,
            zindex: 101,
            cursorcolor: '#007f86',
            cursorborder: '1px solid #007f86',
            cursorwidth: '5px',
            cursoropacitymax: 0.6,
            smoothscroll: true,
            iframeautoresizeL: true,
        });
    }
}

//首页banner图部分swiper
var interleaveOffset = 0.4; //视差比值

var bannerswiper = new Swiper(".index-banner .swiper-container", {
    loop: true,
    speed: 1000,
    watchSlidesProgress: true,
    direction: "vertical",
    autoplay: {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        waitForTransition: false,
    },
    pagination: {
        el: ".index-banner .swiper-pagination",
        clickable: true,
    },
    on: {
        init: function (swiper) {
            $('.index-banner .index-banner-text .text-item').eq(0).fadeIn().siblings().fadeOut()
        },
        slideChangeTransitionStart: function () {
            $('.index-banner .index-banner-text .text-item').eq(this.realIndex).fadeIn().siblings().fadeOut()
        },
        progress: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                var slideProgress = swiper.slides[i].progress;
                var innerOffset = swiper.width * interleaveOffset;
                var innerTranslate = slideProgress * innerOffset;
                swiper.slides[i].querySelector(".slide-inner").style.transform =
                    "translate3d(0," + innerTranslate + "px, 0)";
            }
        },
        touchStart: function (swiper) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = "";
            }
        },
        setTransition: function (swiper, speed) {
            for (var i = 0; i < swiper.slides.length; i++) {
                swiper.slides[i].style.transition = speed + "ms";
                swiper.slides[i].querySelector(".slide-inner").style.transition =
                    speed + "ms";
            }
        }
    }
});


//首页产品部分swiper
var proswiper = new Swiper(".index-product .product-swiper .swiper", {
    autoHeight: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true,
    },
    allowTouchMove: false,
});

// 产品部分效果
!(function () {
    $('.index-product .product-tabs .tab-item').eq(0).addClass('active')
    if ($('.product-swiper .swiper-slide').eq(0).find('.product-list li').length >= 6){
        $('.product-swiper .more-cont .more').eq(0).fadeIn()
    }
})()
// 产品部分效果，产品分类切换
$('.index-product .product-tabs .tab-item').on('click', function (e) {
    e.preventDefault();
    let index = $(this).index()
    $('.index-product .product-tabs .tab-item').eq(index).addClass('active').siblings().removeClass('active')
    proswiper.slideTo(index)
    if ($('.product-swiper .swiper-slide').eq(index).find('.product-list li').length >= 6) {
        $('.product-swiper .more-cont .more').eq(index).fadeIn().siblings().fadeOut()
    }else{
        $('.product-swiper .more-cont .more').fadeOut()
    }
    if (window.devicePixelRatio < 2) {
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
            setTimeout(() => {
                $('body').getNiceScroll().resize()
            }, 500);
        }
    }
})


//页面滚动监听
$(document).scroll(function () {
    var scroH = $(document).scrollTop();
    var viewH = $(window).height();
    var aboutImgTop = $('.index-about .right-img').offset().top;
    var aboutImgBtm = $('.index-about .right-img').offset().top + $('.index-about .right-img').height();
    var searchImgTop = $('.index-search').offset().top;
    var searchImgBtm = $('.index-search').offset().top + $('.index-search').height();


    //search图片位移效果
    if (scroH > searchImgTop - viewH && scroH < searchImgBtm) {
        console.log((searchImgBtm - scroH) / 10)
        let trans = (searchImgBtm - scroH) / 10
        $('.index-search .bg b').css('transform', 'translateY(' + trans + 'px)');
    }

    //about图片位移效果
    if (scroH > aboutImgTop - viewH && scroH < aboutImgBtm) {
        let trans = (aboutImgBtm - scroH) / 10
        $('.index-about .right-img b').css('transform', 'translateY(-' + trans + 'px)');
    }
});
